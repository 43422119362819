/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #10182b;
  padding: 15px 50px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Logo Section */
.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo img {
  width: 150px;
}

/* Hamburger Icon */
.navbar-hamburger {
  display: none; /* Hidden on desktop view */
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.navbar-hamburger span {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  gap: 25px;
  list-style: none;
  transition: transform 0.3s ease;
}

.navbar-links li {
  position: relative;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 8px 12px;
  transition: color 0.3s ease-in-out;
}

/* Active and hover state */
.navbar-links li a:hover,
.active-link {
  color: #1e90ff;
}

.active-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #1e90ff;
  bottom: -10px;
  left: 0;
  transition: transform 0.3s ease-in-out;
}

/* Navbar Buttons */
.navbar-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.navbar-signin {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 8px 15px;
  transition: color 0.3s ease-in-out;
}

.navbar-signin:hover {
  color: #1e90ff;
}

.get-started-btn {
  background-color: #1e90ff;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.get-started-btn:hover {
  background-color: #1473e6;
}

/* Responsive Styles */

/* Tablet view */
@media (max-width: 1024px) {
  .navbar {
    padding: 10px 30px;
  }

  .navbar-hamburger {
    display: flex; /* Show hamburger menu on tablet/mobile */
  }

  .navbar-links {
    display: none; /* Hide links on mobile by default */
  }
}

/* Mobile view */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    position: relative;
  }

  .navbar-logo {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }

  .navbar-logo img {
    max-width: 120px; /* Adjust the logo size for mobile */
  }

  .navbar-hamburger {
    display: flex; /* Show hamburger menu on mobile */
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    position: absolute;
    right: 20px; /* Move hamburger menu to the right */
    top: 15px; /* Align it at the top */
  }

  .navbar-hamburger span {
    width: 25px;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }

  /* Drawer functionality */
  .navbar-links {
    position: fixed;
  
    width: 80%; /* Adjust width to 80% for better visibility */
    /* background-color: #10182b; */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align links to the left */
    justify-content: center; /* Center the links vertically */
    gap: 20px;
    padding: 50px 20px;
    transform: translateX(100%); /* Initially hidden */
    transition: transform 0.3s ease-in-out;
    z-index: 999; /* Ensure the drawer is above other content */
  }

  .navbar-links.open {
    transform: translateX(0); /* Slide in the drawer */
  }

  /* Center the navigation links and adjust padding */
  .navbar-links li {
    width: 100%;
    text-align: left; /* Align text to the left */
  }

  .navbar-links li a {
    font-size: 1.2rem;
    color: white;
    padding: 10px 20px;
    display: block;
    width: 100%; /* Full width for easier clicking */
    text-align: left; /* Align the links to the left */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: add a subtle line between links */
  }

  

  /* Drawer Background Overlay */
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998; /* Slightly lower than the drawer */
  }

  /* Sign In and Get Started button layout */
  .navbar-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  .navbar-signin {
    font-size: 0.9rem;
    padding: 8px 15px;
    text-align: center;
    width: 90%; /* Make the Sign In button take most of the width */
    max-width: 250px; /* Ensure it doesn't get too wide */
    color: white;
  }

  .get-started-btn {
    padding: 12px 24px;
    font-size: 1rem;
    width: 90%;
    max-width: 250px;
    text-align: center;
    background-color: #1e90ff;
    border-radius: 25px;
  }
}

/* Small Mobile view */
@media (max-width: 480px) {
  .navbar {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }

  .navbar-logo {
    margin-bottom: 15px; /* Add more space between logo and links */
  }

  .navbar-hamburger {
    right: 15px; /* Move the hamburger closer to the right edge */
    top: 10px;
  }

  .navbar-links {
    width: 80%; /* Take up full width */
  }

  .navbar-links li a {
    font-size: 1rem;
    padding: 10px;
    display: block;
    width: 100%; /* Ensure links are block elements */
  }

  .navbar-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .navbar-signin {
    font-size: 0.9rem;
    padding: 10px;
    text-align: center;
    width: 100%; /* Make the sign-in button full-width */
  }

  .get-started-btn {
    font-size: 1rem;
    padding: 12px;
    width: 100%; /* Full-width button on smaller devices */
    max-width: 250px;
  }
}
