/* src/components/organisms/HeroSection.css */

.hero-section {
  width: 100%;
  background-color: #10182b;
  padding: 50px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent content from overflowing */
}

/* Slick slider adjustments */
.slick-slider {
  width: 100%; /* Full width for the slider */
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
}

.slick-list {
  width: 100%; /* Ensure the slick list takes up the full width */
  overflow: hidden; /* Prevent overflow of slides */
}

.slick-slide {
  width: 100%; /* Make each slide take up the full width of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Adjust height for responsiveness */
  box-sizing: border-box; /* Ensure padding/margins are included in width/height */
}

/* Ensure hero content and background image */
.hero-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Ensure each slide takes up full width */
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #1a1f36;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  height: 400px; /* Adjust height for larger screens */
  /* background-image: url('https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/slider2.png'); */

}

/* Ensure the hero content scales responsively */
.hero-content {
  max-width: 50%; /* Ensure it takes 50% of the space */
  padding-left: 20px;
  z-index: 2; /* Make sure the content is on top of the background */
}

.hero-title {
  font-size: 2.5rem;
  color: #1e90ff;
  line-height: 1.2;
  font-weight: 600;
}

.hero-title span {
  /* font-size: 2.5rem;   */
  color: white;
}

.hero-description {
  color: white;
  margin: 20px 0;
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 90%;
}

.hero-button {
  background-color: #1e90ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.hero-button:hover {
  background-color: #1473e6;
}

/* Custom Slick Dots */
.slick-dots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  list-style: none;
  gap: 1px !important;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

/* Default inactive dot styling */
.slick-dots li button:before {
  content: '';
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  transition: all 0.3s ease;
}

/* Active dot styling */
.slick-dots li.slick-active button:before {
  content: '';
  display: inline-block;
  width: 18px !important;
  height: 10px !important;
  border-radius: 20px !important;
  background-color: #1e90ff;
  border: none;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .hero-slide {
    flex-direction: column;
    text-align: center;
    height: auto;
    padding: 20px;
    background-position: center;
  }

  .hero-content {
    max-width: 100%;
    padding-left: 0;
    align-items: center; /* Center align for smaller screens */
    text-align: center;
  }

  .hero-title {
    font-size: 2rem;
    margin-bottom: 10px; /* Adjust margin for smaller screens */
  }

  .hero-description {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .slick-dots {
    bottom: 10px;
  }
}

@media (max-width: 768px) {
  .hero-slide {
    height: auto;
    background-size: cover;
    padding: 15px;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-description {
    font-size: 0.9rem;
  }

  .hero-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .hero-slide {
    flex-direction: column;
    padding: 15px;
    height: auto;
    background-size: contain;
    background-position: center center;
  }

  .hero-title {
    font-size: 1.6rem;
  }

  .hero-description {
    font-size: 0.85rem;
  }

  .hero-button {
    font-size: 0.85rem;
    padding: 6px 12px;
  }

  .slick-dots {
    bottom: 5px;
  }
}