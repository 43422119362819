.contact-us-section {
  /* background-color: #2b2c5a; */
  color: white;
  text-align: center;
  padding: 60px 20px; /* Adjusted padding for smaller screens */
    background-image: url('https://stpracticeoncloud.blob.core.windows.net/quantumcatalyst/contactus.png');

}

.contact-us-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-us-subheading {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.contact-us-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allows the buttons to wrap on smaller screens */
}

.get-started-btn1, .contact-us-btn {
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.get-started-btn1 {
  background-color: #1e90ff;
  color: white;
}

.contact-us-btn {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.get-started-btn1:hover {
  background-color: #1473e6;
}

.contact-us-btn:hover {
  background-color: #ffffff;
  color: #1e90ff;
}

/* Media Queries for Responsiveness */

/* For tablets and smaller desktops */
@media (max-width: 992px) {
  .contact-us-heading {
    font-size: 2rem; /* Reduce font size for heading */
  }

  .contact-us-subheading {
    font-size: 1rem; /* Adjust font size for subheading */
  }

  .get-started-btn1, .contact-us-btn {
    padding: 12px 25px; /* Adjust padding for smaller buttons */
    font-size: 0.9rem;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .contact-us-heading {
    font-size: 1.8rem;
  }

  .contact-us-subheading {
    font-size: 0.95rem;
  }

  .contact-us-buttons {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 15px; /* Reduce gap between buttons */
  }

  .get-started-btn1, .contact-us-btn {
    padding: 10px 20px;
    font-size: 0.85rem;
  }
  
}

/* For small mobile devices */
@media (max-width: 480px) {
  .contact-us-heading {
    font-size: 1.5rem;
  }

  .contact-us-subheading {
    font-size: 0.85rem;
  }

  .get-started-btn1, .contact-us-btn {
    padding: 8px 15px;
    font-size: 0.8rem;
  }

  
}
