.about-us-section {
    text-align: center;
    padding: 60px 20px; /* Adjust padding for responsiveness */
    background-color: white;
  }
  
  .about-us-subtitle {
    font-size: 1.2rem;
    color: #10182b;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
  }
  
  .about-us-subtitle::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #1e90ff;
    margin: 8px auto 0;
  }
  
  .about-us-title {
    font-size: 2.5rem;
    color: #333;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .about-us-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Media Queries for Responsiveness */
  
  /* For tablets and smaller desktops */
  @media (max-width: 992px) {
    .about-us-title {
      font-size: 2rem; /* Decrease the font size for smaller screens */
    }
    
    .about-us-description {
      font-size: 0.9rem; /* Slightly smaller font for description */
      max-width: 600px; /* Reduce the max width of the text */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 768px) {
    .about-us-title {
      font-size: 1.8rem; /* Decrease title font size further for mobile */
    }
    
    .about-us-subtitle {
      font-size: 1rem; /* Adjust subtitle font size */
    }
    
    .about-us-description {
      font-size: 0.85rem; /* Further reduce description font size */
      max-width: 90%; /* Allow more space for mobile layout */
    }
    
    .about-us-section {
      padding: 40px 15px; /* Decrease padding on smaller screens */
    }
  }
  
  /* For small mobile devices */
  @media (max-width: 480px) {
    .about-us-title {
      font-size: 1.5rem;
    }
    
    .about-us-subtitle {
      font-size: 0.9rem;
    }
    
    .about-us-description {
      font-size: 0.8rem;
    }
    
    .about-us-section {
      padding: 30px 10px;
    }
  }
  