/* Ensuring the slide itself is transparent */
.slick-slider{
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Optional: If slick-list needs to be transparent */
.slick-list {
  background-color: transparent !important;
}

.testimonial-section {
  padding: 60px 0;
  background-color: #eef1fa; /* Light background color */
  text-align: center;
}

.testimonial-subtitle {
  font-size: 1.2rem;
  color: #10182b;
  font-weight: 500;
  margin-bottom: 10px;
}

.testimonial-subtitle::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: #1e90ff; /* Blue underline */
  margin: 5px auto;
}

.testimonial-title {
  font-size: 2.5rem;
  color: #333;
  font-weight: 400;
  margin-bottom: 40px;
}

.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

/* Testimonial Card */
.testimonial-card {
  padding: 20px;
  border-radius: 10px;
  max-width: 450px;
  width: 100%;
  text-align: left;
  margin: 0 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure the card maintains full height */
  overflow: hidden; /* Prevent text from overflowing outside the card */
  box-sizing: border-box; /* Make sure padding is included in the width/height calculation */
}

.testimonial-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.testimonial-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.testimonial-icon {
  font-size: 1.5rem;
  color: #1e90ff;
}

.testimonial-text {
  font-size: 1rem;
  color: #666;
  margin: 20px 0;
  overflow-wrap: break-word; /* Ensure long words break properly */

}

.testimonial-rating {
  font-size: 1.2rem;
  color: #fbc02d; /* Star color */
  margin-bottom: 10px;
}

.testimonial-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #10182b;
}

.testimonial-location {
  font-size: 0.9rem;
  color: #999;
}

/* Slick Dots */
.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #999;
}

.slick-dots li.slick-active button:before {
  color: #1e90ff; /* Blue color for active dot */
}

/* Responsive styling for smaller screens */
@media (max-width: 1024px) {
  .testimonial-card {
    text-align: center;
    max-width: 100%; /* Make the card take full width on smaller screens */
    margin: 20px 0; /* Add space between the cards */
  }

  .testimonial-header {
    justify-content: center; /* Center the header content */
  }

  .testimonial-text {
    font-size: 0.95rem; /* Slightly reduce text size */
  }

  .testimonial-name {
    font-size: 1rem; /* Adjust the name size */
  }

  .testimonial-rating {
    font-size: 1.1rem; /* Adjust the star rating size */
  }

  .testimonial-location {
    font-size: 0.85rem; /* Slightly reduce location font size */
  }
}

/* Mobile Phones */
@media (max-width: 768px) {
  .testimonial-section {
    padding: 40px 10px; /* Reduce padding for mobile devices */
  }

  .testimonial-title {
    font-size: 2rem; /* Reduce title size */
    margin-bottom: 30px; /* Adjust margin for better spacing */
  }

  .testimonial-subtitle {
    font-size: 1rem; /* Reduce subtitle size */
  }

  .testimonial-card {
    padding: 15px;
    margin: 10px auto; /* Center the cards on mobile */
  }

  .testimonial-header {
    flex-direction: column; /* Stack image and icon vertically */
    align-items: center;
  }

  .testimonial-image {
    width: 40px;
    height: 40px; /* Adjust the image size for mobile */
  }

  .testimonial-icon {
    font-size: 1.2rem;
  }

  .testimonial-text {
    font-size: 0.9rem;
  }

  .testimonial-name {
    font-size: 0.9rem;
  }

  .testimonial-location {
    font-size: 0.8rem;
  }
}

/* Small Mobile Phones */
@media (max-width: 480px) {
  .testimonial-title {
    font-size: 1.8rem; /* Further reduce title size */
  }

  .testimonial-card {
    padding: 10px;
  }

  .testimonial-header {
    gap: 5px; /* Reduce gap between image and icon */
  }

  .testimonial-image {
    width: 35px;
    height: 35px; /* Further reduce image size */
  }

  .testimonial-icon {
    font-size: 1rem;
  }

  .testimonial-text {
    font-size: 0.85rem; /* Make the text smaller */
  }

  .testimonial-name {
    font-size: 0.85rem;
  }

  .testimonial-location {
    font-size: 0.75rem; /* Reduce the location size */
  }

  .testimonial-rating {
    font-size: 1rem;
  }
}
