.why-choose-us-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
    background-color: #e6f3fc; /* Light blue background */
    gap: 20px;
  }
  
  .why-choose-us-content {
    flex: 1;
    max-width: 600px;
  }
  
  .why-choose-us-title {
    font-size: 1.2rem;
    color: #10182b;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
  }
  
  /* Add underline */
  .why-choose-us-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #1e90ff;
    margin-top: 5px;
  }
  
  .why-choose-us-heading {
    font-size: 2.5rem;
    color: #333;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .why-choose-us-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .why-choose-us-features {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .feature-box {
    background-color: white;
    padding: 10px 20px;
    border-radius: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    min-width: 180px;
    text-align: center;
  }
  
  .why-choose-us-image {
    flex: 1;
    max-width: 500px; /* Limit the maximum width of the image */
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
  }
  
  .why-choose-us-image img {
    width: 100%; /* Ensure the image fills its container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive styles */
  
  /* For tablets and smaller screens */
  @media (max-width: 1024px) {
    .why-choose-us-section {
      flex-direction: column;
      padding: 40px 20px; /* Adjusted padding for smaller screens */
      text-align: center;
      gap: 40px; /* Increase gap between elements in column view */
    }
  
    .why-choose-us-image {
      margin-top: 30px;
      max-width: 100%; /* Allow image to take full width */
    }
  
    .why-choose-us-features {
      justify-content: center;
    }
  
    .why-choose-us-content,
    .why-choose-us-image {
      flex: none;
      max-width: 100%; /* Ensure content and image use full width on tablets */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 768px) {
    .why-choose-us-section {
      padding: 30px 15px;
      gap: 30px;
    }
  
    .why-choose-us-heading {
      font-size: 2rem; /* Adjust font size for smaller screens */
    }
  
    .why-choose-us-title {
      font-size: 1rem;
    }
  
    .why-choose-us-description {
      font-size: 0.9rem;
    }
  
    .why-choose-us-features {
      flex-direction: column; /* Stack features vertically */
      align-items: center;
    }
  
    .feature-box {
     
      margin-bottom: 15px;
    }
  }
  
  /* For small mobile devices */
  @media (max-width: 480px) {
    .why-choose-us-heading {
      font-size: 1.8rem;
    }
  
    .why-choose-us-title {
      font-size: 0.9rem;
    }
  
    .why-choose-us-description {
      font-size: 0.85rem;
    }
  
    .why-choose-us-section {
      padding: 20px 10px;
      gap: 20px;
    }
  
   
  }
  