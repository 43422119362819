/* src/components/organisms/Footer.css */

.footer-section {
    background-color: #2b2c5a; /* Dark background for the footer */
    padding: 60px 0;
    color: white;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 40px;
    padding: 20px;
  }
  
  .footer-brand {
    flex: 1;
  }
  
  .footer-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-description {
    font-size: 0.9rem;
    margin-bottom: 20px;
    line-height: 1.6;
    max-width: 280px;
  }
  
 
  .footer-social-icons {
    margin-top: 20px;
  
    
  }
  
  .footer-social-icons a {
    margin-right: 15px;
    display: inline-block; /* Ensure proper inline display */
    transition: color 0.3s ease;
  }
  
  .footer-social-icons a img {
    width: 24px; /* Set fixed width */
    height: 24px; /* Set fixed height */
    object-fit: contain; /* Ensures the image doesn't get distorted */
    transition: transform 0.3s ease; /* Add a smooth transition on hover */
  }
  
  .footer-social-icons a:hover img {
    transform: scale(1.1); /* Slightly increase the size on hover */
  }
  
  .footer-links {
    display: flex;
    flex: 2;
    justify-content: space-between;
  }
  
  .footer-column {
    flex: 1;
  }
  
  .footer-column h4 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    color: white;
    text-decoration: none;
    font-size: 0.95rem;
    transition: color 0.3s ease;
  }
  
  .footer-column ul li a:hover {
    color: #1e90ff; /* Change text color on hover */
  }
  
  .footer-bottom {
    text-align: center;
    padding: 20px;
    font-size: 0.85rem;
    color: #ccc;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 40px;
  }
  
  /* Responsive styling */
  @media (max-width: 1024px) {
    .footer-top {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-links {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  
    .footer-column {
      text-align: center;
    }
  
    .footer-brand {
      margin-bottom: 40px;
    }
  }
  
  @media (max-width: 768px) {
    .footer-column ul li a {
      font-size: 0.85rem;
    }
  
    .footer-description {
      font-size: 0.85rem;
    }
  }
  