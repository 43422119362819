/* src/components/organisms/WorkflowSection.css */

.workflow-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eef3f9; /* Light blue background */
    padding: 60px;
    gap: 40px;
  }
  
  .workflow-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .workflow-content {
    flex: 1;
    max-width: 600px;
  }
  
  .workflow-subtitle {
    font-size: 1.2rem;
    color: #1e90ff; /* Blue color for subtitle */
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
  }
  
  .workflow-subtitle::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #1e90ff;
    margin-top: 5px;
  }
  
  .workflow-title {
    font-size: 2.5rem;
    color: #333;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .workflow-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  .workflow-steps {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .workflow-step {
    display: flex;
    align-items: center;
    gap: 15px;
    width: calc(50% - 10px); /* Two steps per row */
  }
  
  .workflow-icon {
    width: 50px;
    height: 50px;
    background-color: #1e90ff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.5rem;
  }
  
  .workflow-step-content {
    flex: 1;
  }
  
  .workflow-step-content h5 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .workflow-step-content p {
    font-size: 0.95rem;
    color: #666;
    margin: 0;
  }
  
  /* Responsive styling */
  @media (max-width: 1024px) {
    .workflow-section {
      flex-direction: column;
      text-align: center;
    }
  
    .workflow-image {
      margin-bottom: 40px;
    }
  
    .workflow-steps {
      gap: 20px;
      justify-content: center;
    }
  
    .workflow-step {
      width: 100%; /* Full width on smaller screens */
    }
  }
  