/* src/components/organisms/Services.css */

.services-section {
    text-align: center;
    padding: 60px 0;
    background-color: #f9f9f9; /* Light background color */
  }
  
  .services-subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
  }
  
  .services-subtitle::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #1e90ff;
    margin: 8px auto 0;
  }
  
  .services-title {
    font-size: 2.5rem;
    color: #333;
    font-weight: 400;
    margin-bottom: 40px;
  }
  
  .services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service-card {
    background-color: white;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: left;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width:300px;
    height: 350px;
    overflow: hidden; /* Prevent content from overflowing */

  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
  
  .service-icon {
    max-width: 60px;
    margin-bottom: 20px;
  }
  
  .service-title {
    font-size: 1.5rem;
    color: #ffff;
    margin-bottom: 15px;
  }
  
  .service-description {
    font-size: 1rem;
    color: #ffff;
    line-height: 1.6;
  }
  
/* Responsive Styles */

/* For Tablets */
@media (max-width: 1024px) {
    .services-container {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
      gap: 30px; /* Increase gap for better spacing */
      padding: 0 20px; /* Add padding for better alignment */
    }
  
    .service-card {
      padding: 20px; /* Reduce padding to fit the content well */
      min-height: 300px; /* Ensure a balanced height */
      height: auto; /* Let height adjust automatically */
    }
  
    .service-title {
      font-size: 1.3rem; /* Slightly smaller font size */
    }
  
    .service-description {
      font-size: 0.95rem; /* Reduce the font size for descriptions */
      line-height: 1.5;
    }
  }
  
  /* For Mobile Phones */
  @media (max-width: 768px) {
    .services-container {
      grid-template-columns: repeat(1, 1fr); /* 1 card per row */
      gap: 20px; /* Slightly reduced gap for smaller screens */
      padding: 0 15px; /* Reduce padding for smaller screens */
    }
  
    .service-card {
      padding: 15px; /* Adjust padding for mobile devices */
      min-height: 280px; /* Ensure cards don't get too tall */
      margin: 0 auto; /* Center cards on mobile */
    }
  
    .service-title {
      font-size: 1.2rem; /* Smaller title for mobile */
    }
  
    .service-description {
      font-size: 0.85rem; /* Adjust description font size */
      line-height: 1.5;
    }
  }
  
  /* For Small Mobile Phones */
  @media (max-width: 480px) {
    .services-container {
      gap: 15px; /* Reduce gap to prevent cards from feeling too distant */
      padding: 0 10px; /* Ensure proper spacing on very small screens */
    }
  
    .service-card {
      padding: 10px; /* Reduce padding for very small screens */
      min-height: 240px; /* Lower the height for small devices */
      margin: 0 auto; /* Center cards properly */
    }
  
    .service-title {
      font-size: 1.1rem; /* Smaller font size for title */
    }
  
    .service-description {
      font-size: 0.8rem; /* Further reduce description size */
      line-height: 1.4; /* Slightly reduce line-height for better spacing */
    }
  
    .service-icon {
      max-width: 50px; /* Adjust icon size for small devices */
      margin-bottom: 15px; /* Reduce space between icon and content */
    }
  }
  